import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
} from "@mui/material";
import { useGetDestinationCategoryQuery } from "state/api";
import Footer from "components/Footer";
import HomepageNav from "components/HomepageNav";
import { useParams } from "react-router-dom";
import FloatingSocialIcons from "components/FloatingSocialIcons";

const DestinationList = () => {
  const { category } = useParams();
  const { data, isLoading } = useGetDestinationCategoryQuery(category);
  const destinations = data?.data || []; // Access blogs array from the API response

  if (isLoading) {
    // Show a loading spinner while fetching data
    return <div>Loading...</div>;
  }

  return (
    <div style={{ background: "white" }}>
      <HomepageNav />
      <div>
        <Box
          sx={{
            m: {
              xs: "1rem 2rem",
              sm: "1rem 2.5rem",
            },
          }}
        >
          <Box
            sx={{
              height: "80vh",
              my:9,
              overflow: "auto",
              p: {
                xs: "0.5rem",
                sm: "1rem",
                md: "1rem",
              },
            }}
          >
            <Typography
              variant="h1"
              style={{
                color: "#008000",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              {category} Destinations.
            </Typography>
            {destinations.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {destinations.map((destination) => (
                  <Card
                    key={destination._id}
                    sx={{ width:{
                      xs: "100%",
                      sm: "320px",
                      md: "390px"

                    }, borderRadius: "0.5rem" }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="160px"
                        image={destination.photos[0]?.url}
                        alt={destination.title}
                      />
                      <CardContent sx={{ backgroundColor: "#F6F6F6" }}>
                        <Typography
                          gutterBottom
                          sx={{
                            color: "black",
                            textTransform: "capitalize !important",
                            fontSize:{
                              xs: "12px",
                              sm: "14px",
                              md: "16px",
                            },
                            pb:{
                              xs: "0rem",
                              sm: "0.5rem",
                            },
                          }}
                        >
                          {destination.title}
                        </Typography>
                        <Typography
                          gutterBottom
                          component="div"
                          sx={{ color: "black", fontSize:{
                            xs: "10px",
                            sm: "11px",
                            md: "12px"
                          },
                        visibility:{
                          xs: "hidden",
                          sm: "visible",
                        }}}
                        >
                          RefNo: {destination.refNo}
                        </Typography>
                        <a
                          href={`/destinationPage/${destination._id}`}
                          className="btn"
                          style={{
                            backgroundColor: "#008000",
                            color: "white",
                            padding: "10px 10px",
                            borderRadius: "5px",
                            textDecoration: "none",
                            display: "inline-block",
                            textAlign: "center",
                          }}
                        >
                          View Destination
                        </a>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            ) : (
              <p style={{ color: "black" }}>
                No {category} destinations available.
              </p>
            )}
          </Box>
        </Box>
      </div>
      <Footer />
      <FloatingSocialIcons />
    </div>
  );
};

export default DestinationList;
