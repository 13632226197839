import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
    GridViewOutlined,
    BookOutlined,
    FmdGoodOutlined,
    DiscountOutlined,
    VillaOutlined,
    LoginOutlined,
    PersonOutlined,
    AirportShuttleOutlined,
    ChevronLeft,
    ChevronRightOutlined,
  } from "@mui/icons-material";
  import { useEffect, useState } from "react";
  import { useLocation,useNavigate} from "react-router-dom";
  import FlexBetween from "./FlexBetween";

  const navItems = [
    {
      text: "Dashboard",
      icon: <GridViewOutlined />,
    },
    {
      text: "Blogs",
      icon: <BookOutlined/>,
    },
    {
      text: "Destinations",
      icon: <FmdGoodOutlined/>,
    },
    {
      text: "TopDeals",
      icon: <DiscountOutlined/>,
    },
    {
      text: "ManageVillas",
      icon: <VillaOutlined/>,
    },
    {
      text: "CarFleet",
      icon: < AirportShuttleOutlined/>,
    },
    {
      text: "Management",
      icon: null,
    },

    {
      text: "Users",
      icon: <PersonOutlined/>,
    },
    {
      text: "Logout",
      icon: <LoginOutlined/>,
    },
  ];

  
  const Sidebar = ({
    drawerWidth,
    isSidebarOpen,
    setIsSidebarOpen,
    isNonMobile,
    userRole
  }) => {
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();
  
    useEffect(() => {
      setActive(pathname.substring(1));
    }, [pathname]);


  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={()=>setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold">
                    CUPSTONE
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
            {navItems.map(({ text, icon }) => {
                // Hide the "Users" tab if the user is not a superadmin
                if (text === "Users" && userRole !== "superadmin") {
                  return null; // Do not render this item
                }
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default Sidebar
