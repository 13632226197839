import React from 'react';
import { Box, Card, CardContent, CardMedia, CardActionArea, Typography } from '@mui/material';
import { useGetBlogsQuery } from 'state/api';
import Footer from 'components/Footer';
import HomepageNav from 'components/HomepageNav';
import FloatingSocialIcons from 'components/FloatingSocialIcons';

const Blogs = () => {
  const { data, isLoading } = useGetBlogsQuery();
  const blogs = data?.data || []; // Access blogs array from the API response

  return (
    <div style={{ background: 'white' }}>
      <HomepageNav />
      <div>
      <Box m="1.5rem 2.5rem">
        <Box sx={{ height: '75vh', my: 9, overflow: 'auto', p: 2 }}>
        <h1 style={{ color: '#008000', textAlign: "center", fontWeight: "bold", textDecoration: "underline",}}>Blogs</h1>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }} loading={isLoading}>
            {blogs.map((blog) => (
              <Card key={blog._id} sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="160"
                    image={blog.photos[0]?.url}
                    alt={blog.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" >
                      {blog.title}
                    </Typography>
                    <a
                         href={`/blogPage/${blog._id}`}
                          className="btn"
                          style={{
                            backgroundColor: "#008000",
                            color: "white",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            textDecoration: "none",
                            display: "inline-block",
                            textAlign: "center",
                          }}
                        >
                          Read More
                        </a>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        </Box>
        </Box>
      </div>
      <Footer />
      <FloatingSocialIcons/>
    </div>
  );
};

export default Blogs;
