import React from "react";
import { IconButton } from "@mui/material";
import { Facebook, Instagram, LinkedIn, WhatsApp, X } from "@mui/icons-material";
import { AiFillTikTok } from "react-icons/ai";
import styled from "styled-components";

const SocialContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.9;
  border-radius: 0 8px 8px 0;
  z-index: 1000;

  @media (max-width: 768px) {
    top: auto;
    bottom: 10px;
    left: 10px;
    transform: none;
    flex-direction: row;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.8);
  }
`;

const FloatingSocialIcons = () => {
  return (
    <SocialContainer>
      <a href="https://www.facebook.com/cupstonetravel/" target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="Facebook">
          <Facebook />
        </IconButton>
      </a>
      <a href="https://x.com/Cupstone_Kenya?t=CC7LJAjVR3_A2mFGc_8sMA&s=09" target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="Twitter">
          <X />
        </IconButton>
      </a>
      <a href="https://www.tiktok.com/@_cupstonetravel?_t=8oQQEHhEMCb&_r=1" target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="TikTok">
          <AiFillTikTok />
        </IconButton>
      </a>
      <a href="https://www.instagram.com/cupstonetravel?igsh=cXRuem05bW4xMHcy" target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="Instagram">
          <Instagram />
        </IconButton>
      </a>
      <a href="https://ke.linkedin.com/in/cupstone-travel-237a9434" target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="LinkedIn">
          <LinkedIn />
        </IconButton>
      </a>
      <a href="https://wa.me/254740496609" target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="WhatsApp">
          <WhatsApp />
        </IconButton>
      </a>
    </SocialContainer>
  );
};

export default FloatingSocialIcons;
