import { React, useState } from "react";
import Header from "components/Header";
import { Box, TextField, useTheme, Button } from "@mui/material";
import { IoIosImages } from "react-icons/io";
import { BiTrash } from "react-icons/bi";
import ImageIcon from "@mui/icons-material/Image";
import { Add } from "@mui/icons-material";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { message } from "antd";

const CreateBlog = () => {
  const theme = useTheme();

  // UPLOAD, DRAG AND DROP PHOTOS
  const [photos, setPhotos] = useState([]);
  const [title, setTitle] = useState('');
  const [blogContent, setBlogContent] = useState('');

  const handleUploadPhotos = (e) => {
    const newPhotos = e.target.files;
    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
  };

  const handleDragPhoto = (result) => {
    if (!result.destination) return;
    const items = Array.from(photos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPhotos(items);
  };

  const handleRemovePhoto = (indexToRemove) => {
    setPhotos((prevPhotos) =>
      prevPhotos.filter((_, index) => index !== indexToRemove)
    );
  };
  // END UPLOAD, DRAG AND DROP PHOTOS

  const handleBlogContentChange = (value) => {
    setBlogContent(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', blogContent);
      photos.forEach(photo => formData.append('images', photo));
  
      const response = await fetch('https://cupstonetravel.com:8444/management/blogs', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Include the token here
        },
        body: formData,
      });
  
      const result = await response.json();
      message.success("Blog Created Successfully");
      if (response.ok) {
        message.success("Blog Created Successfully")
        setTitle('');
        setBlogContent('');
        setPhotos([]);
      } else {
        console.error('Failed to create blog:', result.message);
        alert('Failed to create blog');
      }
    } catch (error) {
      console.error('Failed to create blog:', error);
      alert('Failed to create blog');
    }
  };
  
  

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Create a New Blog Post"
        subtitle="Write and publish a new blog"
      />
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          "& > *": {
            mt: 5,
          },
        }}
        onSubmit={handleSubmit}

      >
        <TextField
          label="Enter title for Blog"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{
            marginBottom: "30px",
          }}
        />
      <Box>
        <Button
          variant="outlined"
          startIcon={<ImageIcon />}
          sx={{
            marginBottom: "30px",
          }}
        >
          <DragDropContext onDragEnd={handleDragPhoto}>
            <Droppable droppableId="photos" direction="horizontal">
              {(provided) => (
                <div
                  className="photos"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {photos.length < 1 && (
                    <>
                      <input
                        id="image"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleUploadPhotos}
                        multiple
                      />
                      <label htmlFor="image" className="alone">
                        <div className="icon">
                          <IoIosImages />
                        </div>
                        <p>Upload From Your Device</p>
                      </label>
                    </>
                  )}

                  {photos.length >= 1 && (
                    <>
                      {photos.map((photo, index) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="photo"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <img
                                src={URL.createObjectURL(photo)}
                                alt="blog"
                              />
                              <button
                                type="button"
                                onClick={() => handleRemovePhoto(index)}
                              >
                                <BiTrash />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      <input
                        id="image"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleUploadPhotos}
                        multiple
                      />
                      <label htmlFor="image" className="together">
                        <div className="icon">
                          <IoIosImages />
                        </div>
                        <p>Upload From Your Device</p>
                      </label>
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Button>
      </Box>

      <ReactQuill
        value={blogContent}
        onChange={handleBlogContentChange}
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image"],
            ["clean"],
          ],
        }}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
        ]}
        style={{ height: "300px" }}
        sx={{
          marginBottom: "30px",
        }}
      />
      <Box
        display="flex"
        sx={{
          my: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <Button
          variant="outlined"
          type="submit"
          sx={{
            color: theme.palette.secondary[100],
            borderColor: theme.palette.secondary[300],
          }}
          endIcon={<Add />}
        >
          Publish
        </Button>
      </Box>
      </Box>

     
    </Box>
  );
};

export default CreateBlog;
