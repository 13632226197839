import React from "react";
import { Box } from "@mui/material";
import Footer from "components/Footer";
import HomepageNav from "components/HomepageNav";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/home.css";
import sedan from "../assets/sedan.jpg";
import suv from "../assets/prado.jpg";
import van from "../assets/van.jpg";
import minivan from "../assets/minivan.png";
import bus from "../assets/bus.jpg";
import safariVehicle from "../assets/safarivehicle.jpg";
import miniBus from "../assets/minibus.jpg";
import FloatingSocialIcons from "components/FloatingSocialIcons";

const fleets = [
  { name: "Sedan", image: sedan },
  { name: "SUV", image: suv },
  { name: "Mini-Bus", image: miniBus },
  { name: "Van", image: van },
  { name: "Mini-Van i.e Noah", image: minivan },
  { name: "Safari-Vehicle", image: safariVehicle },
  { name: "Bus", image: bus },
];

const FleetPage = () => {
  return (
    <div style={{ background: "white" }}>
      <HomepageNav />
      <div>
      <Box m="1.5rem 2.5rem">
        <Box sx={{ height: "80vh", my: 9, overflow: "auto", p: 2 }}>
          <section
            className="destinations-section"
            style={{ backgroundColor: "white" }}
          >
            <h2 className="text-center fw-bold" style={{ color: "#008000" }}>
              Our Fleet
            </h2>
            <p className="text-center" style={{ color: "#008000" }}>
              Explore The Best Rides At Affordable Prices
            </p>
            <div className="container">
              <div className="row">
                {fleets.map((fleet, index) => (
                  <div key={index} className="col-md-4 mb-4">
                    <div className="card destination-card animate-fade-in">
                      <img
                        src={fleet.image}
                        className="card-img-top"
                        alt={fleet.name}
                      />
                      <div className="card-img-overlay d-flex flex-column justify-content-end">
                        <h5 className="card-title">{fleet.name}</h5>
                        <a href={`/carList/${fleet.name}`}
                        className="btn btn-primary"
                        >View Cars</a>
                        
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </Box>
        </Box>
      </div>
      <Footer />
      <FloatingSocialIcons/>
    </div>
  );
};

export default FleetPage;
