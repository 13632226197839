import React, { useState } from 'react';
import Header from 'components/Header';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, Visibility } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { message } from 'antd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useGetCarQuery, useDeleteCarByIdMutation } from 'state/api';
import styled from 'styled-components';

const StyledContent = styled(Typography)`
  white-space: pre-wrap;
`;

const CarFleet = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useGetCarQuery();
  const [deleteCarById] = useDeleteCarByIdMutation();
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [selectedCarId, setSelectedCarId] = useState(null);
  const [selectedCar, setSelectedCar] = useState(null);

  const handleDeleteClick = (carId) => {
    setSelectedCarId(carId);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteCarById(selectedCarId).unwrap();
      message.success("Car deleted successfully");
      refetch(); // Refresh the data
    } catch (error) {
      message.error("Failed to delete Car");
    } finally {
      setOpenDialog(false);
    }
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
  };

  const handleViewClick = (car) => {
    setSelectedCar(car);
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const columns = [
    {
      field: 'photos',
      headerName: 'Photos',
      flex: 1,
      width: 100,
      renderCell: (params) => {
        const photoUrl = params.value[0]?.url;
        return (
          <img src={photoUrl} alt="Car" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        );
      },
    },
    {
      field: 'location',
      headerName: 'Located',
      flex: 0.5,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 0.5,
    },
    {
      field: 'price',
      headerName: 'Price KES',
      flex: 0.7,
    },
    {
      field: 'carType',
      headerName: 'Car Type',
      flex: 0.5,
    },
    {
      field: 'sittingCapacity',
      headerName: 'Sitting Capacity',
      flex: 0.8,
    },
    {
      field: 'fuelType',
      headerName: 'Fuel Type',
      flex: 0.5,
    },
    {
      field: 'view',
      headerName: 'View',
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="success"
          onClick={() => handleViewClick(params.row)}
          endIcon={<Visibility />}
        >
          View
        </Button>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDeleteClick(params.row._id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Car Fleet" subtitle="Entire list of Cars" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data?.data || []}
          columns={columns}
          rowHeight={100}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ my: '1.5rem' }}>
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.secondary[100],
            borderColor: theme.palette.secondary[300],
          }}
          endIcon={<Add />}
          onClick={() => navigate('/createcar')}
        >
          Add Car
        </Button>
      </Box>

      {/* Deletion Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Car?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Car Dialog */}
      <Dialog open={openViewDialog} onClose={handleCloseViewDialog} maxWidth="md" fullWidth>
        <DialogTitle>Car Details</DialogTitle>
        <DialogContent>
          {selectedCar && (
            <Box>
              <Typography variant="h6">ID: {selectedCar._id}</Typography>
              <Typography variant="body1">Title: {selectedCar.title}</Typography>
              <Typography variant="body1">Location: {selectedCar.location}</Typography>
              <Typography variant="body1">Price: {selectedCar.price} KES</Typography>
              <Typography variant="body1">Car Type: {selectedCar.carType}</Typography>
              <Typography variant="body1">Engine Size Ltr: {selectedCar.engineSize}</Typography>
              <Typography variant="body1">Sitting Capacity: {selectedCar.sittingCapacity}</Typography>
              <Typography variant="body1">Fuel Type: {selectedCar.fuelType}</Typography>
              <Typography variant="body1">Current Location: {selectedCar.location}</Typography>
              {/* <Box mt={2}>
                <Typography variant="h6">Photos:</Typography>
                {selectedCar.photos.map((photo, index) => (
                  <img
                    key={index}
                    src={photo.url}
                    alt={`Car ${index}`}
                    style={{ width: "100%", height: "auto", marginTop: "10px" }}
                  />
                ))}
              </Box> */}
              <Typography variant="h6" mt={2}>Description</Typography>
              <StyledContent dangerouslySetInnerHTML={{ __html: selectedCar.description }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CarFleet;
