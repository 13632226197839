import React from 'react';
import Header from 'components/Header'; // Ensure this path is correct
import { Box, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Add } from '@mui/icons-material';
import { message } from 'antd';
import { clearUser } from 'state/userSlice';

const Logout = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogOut = () => {
        localStorage.removeItem('token');
        dispatch(clearUser()); // Clear localStorage on logout
        message.success("Logout Successfully"); // Show success message
        navigate("/"); // Navigate to home page
    };

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="Goodbye" /> {/* Display header with title */}

            <Box 
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ my: '1.5rem' }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        color: theme.palette.secondary[100],
                        borderColor: theme.palette.secondary[300],
                    }}
                    endIcon={<Add />}
                    onClick={handleLogOut}
                >
                    LOG OUT
                </Button>
            </Box>
        </Box>
    );
};

export default Logout;
