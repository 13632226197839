import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import iata from "../assets/iata.svg"
import kws from "../assets/kws.webp"
import kato from "../assets/kato.png"
import magicalKenya from "../assets/magicalKenya.png"
const Footer = () => {
  return (
    <footer className="text-white" style={{ backgroundColor: "#008000", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center"}}>
        <div className="container py-5">
            <div className="row">
            <div className="col-md-4 mb-3">
                <h5>CUPSTONE TRAVEL LTD</h5>
                <p>CUPSTONE TRAVEL LTD provides consultancy and travel services, reservation of air, 
                    land and sea arrangement form of transport, accommodation, adventure, 
                    leisure and business travel clients, and custom designed tours in Kenya and East Africa.
                </p>
                <div className="d-flex justify-content-start">
                <a href="https://www.facebook.com/cupstonetravel/" className="me-2 text-white"><i className="fab fa-facebook-f"></i></a>
                <a href="https://x.com/Cupstone_Kenya?t=CC7LJAjVR3_A2mFGc_8sMA&s=09" className="me-2 text-white"><i className="fab fa-x-twitter"></i></a>
                <a href="https://www.instagram.com/cupstonetravel?igsh=cXRuem05bW4xMHcy" className="text-white"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <h5>CONTACT US</h5>
                <p>
                Drop us a line.<br />
                Offices are at Life Ministry,1st floor room 11 in Nairobi <br/>
                Yusuf Ali building, Along Moi Avenue ground floor in Mombasa.<br />
                sales@cupstonetravel.com<br />
                +254 724 262981<br />
                +254 740 496609 <br />
                0112892587<br />
                </p>
            </div>
            <div className="col-md-4 mb-3">
                <h5>OUR REVIEWS</h5>
                <ul className="list-unstyled" >
                <li><a href="https://www.facebook.com/cupstonetravel/" className="text-white"style={{textDecoration:"none"}}>CupstoneTravel- Facebook</a></li>
                <li><a href="https://www.tiktok.com/@_cupstonetravel?_t=8oQQEHhEMCb&_r=1" className="text-white"style={{textDecoration:"none"}}>CupstoneTravel- TikTok</a></li>

                </ul>
            </div>
            </div>
            <div className="row mt-4" >
            <div className="col-12 text-center">
                <h5>OUR PARTNERS</h5>
                <div className="d-flex justify-content-center align-items-center" style={{backgroundColor:"white"}}>
                <a href="https://www.iata.org/" className="mx-2"><img src={iata} alt="Partner 1" className="img-fluid" style={{ maxHeight: "60px" }} /></a>
                <a href="https://www.kws.go.ke/" className="mx-2"><img src={kws} alt="Partner 2" className="img-fluid" style={{ maxHeight: "60px" }} /></a>
                <a href="https://katokenya.org/" className="mx-2"><img src={kato} alt="Partner 3" className="img-fluid" style={{ maxHeight: "60px" }} /></a>
                <a href="https://magicalkenya.com/" className="mx-2"><img src={magicalKenya} alt="Partner 4" className="img-fluid" style={{ maxHeight: "60px" }} /></a>
                </div>
            </div>
            </div>
        </div>
        <div className="text-center py-3" style={{ backgroundColor: "#006400", width:"100%"}}>
                &copy; 2024 CUPSTONE TRAVEL LTD. All Rights Reserved.
        </div>
        </footer>


  )
}

export default Footer
