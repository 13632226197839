import React from 'react'
import HomepageNav from 'components/HomepageNav';
import Footer from 'components/Footer'
import map from '../assets/map.png'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import FloatingSocialIcons from 'components/FloatingSocialIcons';
const Contactpage = () => {
  return (
    <div>
      <div>
        <HomepageNav/>

        <Container fluid className="p-5" style={{ backgroundColor: '#f8f9fa', marginTop: '40px'}}>
            <Container fluid>
                <Row className="mb-4 justify-content-center text-center">
                <Col md={8}>
                    <span class="text-muted">Let's Talk</span>
                    <h1 className="text-center fw-bold" style={{color:"#008000"}}>Contact Us</h1>
                </Col>
                </Row>
                <Row style={{marginTop: '50px'}}>
                <Col md={6}>
                    <h5 style={{color:"#008000", fontWeight:'bold'}}>Address</h5>
                    <p style={{color:"black"}}>Cupstone Tours & Travel, Nairobi Central Uniafric House, Nairobi, Mombasa</p>
                    <h5 style={{color:"#008000", fontWeight:'bold'}}>Call Us</h5>
                    <p style={{color:"black"}}>+254 724 262981</p>
                    <h5 style={{color:"#008000", fontWeight:'bold'}}>Email</h5>
                    <p style={{color:"black"}}>sales@cupstonetravel.com</p>
                    <h5 style={{color:"#008000", fontWeight:'bold'}}>Follow Us</h5>
                    <p>
                    <a href="https://www.facebook.com/cupstonetravel/" className="btn btn-primary btn-sm me-2"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://x.com/Cupstone_Kenya?t=CC7LJAjVR3_A2mFGc_8sMA&s=09" className="btn btn-info btn-sm me-2"><i className="fab fa-x-twitter"></i></a>
                    <a href="https://ke.linkedin.com/in/cupstone-travel-237a9434" className="btn btn-secondary btn-sm"><i className="fab fa-linkedin-in"></i></a>
                    </p>
                    <h5 style={{color:"#008000", fontWeight:'bold'}}>Location</h5>
                    <img src={map} alt="Map" className="img-fluid" />
                </Col>
                <Col md={6}>
                    <h5 style={{color:"#008000", fontWeight:'bold'}}>Get in Touch</h5>
                    <Form action="https://formsubmit.co/2a0c46b72f8c29e6f41c51036dad2c82" method="POST" >
                    <Form.Group className="mb-3 fw-bold" style={{color:"black"}} controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter your name" name='FullName' />
                    </Form.Group>
                    <Form.Group className="mb-3 fw-bold"style={{color:"black"}} controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email fw-bold" style={{color:"black"}} name='email' placeholder="Enter your email" />
                    </Form.Group>
                    <Form.Group className="mb-3 fw-bold" style={{color:"black"}}  controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={5} name='message' placeholder="Enter your message" />
                    </Form.Group>
                    <Button style={{backgroundColor:"#008000", borderColor:"#008000"}} type="submit">
                        Send Message
                    </Button>
                    </Form>
                </Col>
                </Row>
            </Container>
            </Container>
            <FloatingSocialIcons/>

        <Footer/>
      </div>
    </div>
  )
}

export default Contactpage
