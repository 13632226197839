import {React, useState} from 'react'
import Header from 'components/Header'
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete
} from "@mui/material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { IoIosImages } from "react-icons/io";
import { BiTrash } from "react-icons/bi";
import ImageIcon from "@mui/icons-material/Image";
import { message } from 'antd';
import { useCreateCarMutation } from 'state/api';
import { Counties } from 'state/countiesData';

const CreateCar = () => {
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [engineSize, setEngineSize] = useState('');
  const [country, setCountry] = useState('');
  const [location, setLocation] = useState('');
  const [sittingCapacity, setSittingCapacity] = useState('');
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState("");
  const [selectedDriveType, setSelectedDriveType] = useState("");
  const [selectedFuelType, setSelectedFuelType] = useState("");
  const [createCar] = useCreateCarMutation();

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handlePriceChange = (e) => setPrice(e.target.value);
  const handleEngineSizeChange = (e) => setEngineSize(e.target.value);
  const handleCountryChange = (e) => setCountry(e.target.value);
  //const handleLocationChange = (e) => setLocation(e.target.value.toLowerCase());
  const handleLocationChange = (event, newValue) => {
    // Check if newValue is not null or undefined and then convert to lowercase
    setLocation(newValue ? newValue.toLowerCase() : '');
  };

  const handleSittingCapacityChange = (e) => setSittingCapacity(e.target.value);

  const handleCarTypeChange = (event) => {
    setSelectedCarType(event.target.value);
  };
  const handleDriveTypeChange = (event) => {
    setSelectedDriveType(event.target.value);
  };
  const handleFuelTypeChange = (event) => {
    setSelectedFuelType(event.target.value);
  };

  const handleUploadPhotos = (e) => {
    const newPhotos = e.target.files;
    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
  };

   // UPLOAD, DRAG AND DROP PHOTOS
  const handleDragPhoto = (result) => {
    if (!result.destination) return;
    const items = Array.from(photos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPhotos(items);
  };

  const handleRemovePhoto = (indexToRemove) => {
    setPhotos((prevPhotos) =>
      prevPhotos.filter((_, index) => index !== indexToRemove)
    );
  };
 // END UPLOAD, DRAG AND DROP PHOTOS

  const handleQuillChange = (content) => {
    setDescription(content);
  };


  // Handle Submition
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('price', price);
      formData.append('engineSize', engineSize);
      formData.append('country', country);
      formData.append('location', location);
      formData.append('sittingCapacity', sittingCapacity);
      formData.append('description', description);
      formData.append('carType', selectedCarType);
      formData.append('driveType', selectedDriveType);
      formData.append('fuelType', selectedFuelType);
  
      photos.forEach(photo => formData.append('images', photo));
  
      const response = await createCar(formData).unwrap();
      setTitle('');
      setPrice('');
      setEngineSize('');
      setCountry('');
      setLocation('');
      setSittingCapacity('');
      setDescription('');
      setPhotos([]);
      setSelectedCarType('');
      setSelectedDriveType('');
      setSelectedFuelType('');
      message.success('Car created successfully');
      if(response.ok)
      {
        message.success('Car created successfully');
      }
    } catch (error) {
      console.error('Error creating car:', error);
      message.error(`Error creating car: ${error.message}`);
    }
  };
  
  
  return (
   <Box m="1.5rem 2.5rem">
    <Header title="Create a New Car" subtitle="Write and publish a new car"/>

    <Box sx={{ my: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Car Title"
              variant="outlined"
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Country"
              variant="outlined"
              value={country}
              onChange={handleCountryChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Current Location i.e nairobi"
              variant="outlined"
              value={location}
              onChange={handleLocationChange}
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={Counties.map(county => county.name || county.capital)}
              value={location}
              onChange={(event, newValue) => handleLocationChange(event, newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Current Location i.e nairobi"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Prices Start From"
              variant="outlined"
              value={price}
              onChange={handlePriceChange}
            />
          </Grid>
          {/* GUIDE FOR DROP DOWN */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="carType-label">Car Type</InputLabel>
              <Select
                labelId="carType-label"
                id="carType-select"
                value={selectedCarType}
                onChange={handleCarTypeChange}
                label="Car Type"
              >
                <MenuItem value="SUV">SUV</MenuItem>
                <MenuItem value="Safari-Vehicle">Safari-Vehicle</MenuItem>
                <MenuItem value="Sedan">Sedan</MenuItem>
                <MenuItem value="Van">Van</MenuItem>
                <MenuItem value="Mini-Bus">Mini-Bus</MenuItem>
                <MenuItem value="Bus">Bus</MenuItem>
                <MenuItem value="Mini-Van i.e Noah">Mini-Van i.e Noah</MenuItem>

              </Select>
            </FormControl>
          </Grid>
          {/* END OF DROP DOWN */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Engine Size"
              variant="outlined"
              value={engineSize}
              onChange={handleEngineSizeChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Sitting Capacity"
              variant="outlined"
              value={sittingCapacity}
              onChange={handleSittingCapacityChange}
            />
          </Grid>
     {/* GUIDE FOR DROP DOWN */}
     <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="driveType-label">Drive Type</InputLabel>
              <Select
                labelId="driveType-label"
                id="driveType-select"
                value={selectedDriveType}
                onChange={handleDriveTypeChange}
                label="Drive Type"
              >
                <MenuItem value="AWD">A-WD</MenuItem>
                <MenuItem value="RWD">R-WD</MenuItem>
                <MenuItem value="FWD">F-WD</MenuItem>
                <MenuItem value="FWD">4-WD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* END OF DROP DOWN */}
          
          {/* GUIDE FOR DROP DOWN */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="fuelType-label">Fuel Type</InputLabel>
              <Select
                labelId="fuelType-label"
                id="fuelType-select"
                value={selectedFuelType}
                onChange={handleFuelTypeChange}
                label="Fuel Type"
              >
                <MenuItem value="Diesel">Diesel</MenuItem>
                <MenuItem value="Petrol">Petrol</MenuItem>
                <MenuItem value="Hybrid">Hybrid</MenuItem>
                <MenuItem value="Electric">Electric</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* END OF DROP DOWN */}
        </Grid>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>
          Car Description
        </Typography>
        <ReactQuill
          theme="snow"
          value={description}
          onChange={(content) => handleQuillChange(content, "description")}
          modules={{
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link', 'image'],
              ['clean']
            ],
          }}
          formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet',
            'link', 'image'
          ]}
          style={{ height: '200px' }}
        />
      </Box>

       {/* PHOTO INPUT  */}
       <Box sx={{ mt: 10 }}>
        <Button
          variant="outlined"
          startIcon={<ImageIcon />}
        >
          <DragDropContext onDragEnd={handleDragPhoto}>
            <Droppable droppableId="photos" direction="horizontal">
              {(provided) => (
                <div
                  className="photos"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {photos.length < 1 && (
                    <>
                      <input
                        id="image"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleUploadPhotos}
                        multiple
                      />
                      <label htmlFor="image" className="alone">
                        <div className="icon">
                          <IoIosImages />
                        </div>
                        <p>Upload From Your Device</p>
                      </label>
                    </>
                  )}

                  {photos.length >= 1 && (
                    <>
                      {photos.map((photo, index) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="photo"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <img
                                src={URL.createObjectURL(photo)}
                                alt="blog"
                              />
                              <button
                                type="button"
                                onClick={() => handleRemovePhoto(index)}
                              >
                                <BiTrash />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      <input
                        id="image"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleUploadPhotos}
                        multiple
                      />
                      <label htmlFor="image" className="together">
                        <div className="icon">
                          <IoIosImages />
                        </div>
                        <p>Upload From Your Device</p>
                      </label>
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Button>
      </Box>

      {/* END OF PHOTO INPUT */}

      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Publish New Car
        </Button>
      </Box>

   </Box>
  )
}

export default CreateCar
