import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'scenes/layout';

const ProtectedLayout = ({ children }) => {
  const user = useSelector((state) => state.user.user);

  // Check if the user is authenticated
  if (!user) {
    return <Navigate to="/signin" />;
    
  }

  return <Layout>{children}</Layout>;
};

export default ProtectedLayout;
