import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Container,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetBlogByIdQuery } from "state/api";
import Footer from "components/Footer";
import HomepageNav from "components/HomepageNav";
import styled from "styled-components";
import { motion } from "framer-motion";
import FloatingSocialIcons from "components/FloatingSocialIcons";

const StyledHeroImage = styled(motion.img)`
  max-width: 60%;
  display: block;
  margin: 0 auto; /* Center the image horizontally */
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;


const StyledContent = styled.div`
  margin: 20px 0;
  line-height: 1.6;
`;

const StyledAuthorDate = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #888;
  margin-top: 20px;
`;

const BlogPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetBlogByIdQuery(id);
  const [open, setOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const blog = data?.data[0]; // Assuming data.data is an array

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const handleClickOpen = (photo) => {
    setSelectedPhoto(photo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPhoto(null);
  };

  return (
    <div
      style={{ background: "#E8EDF4FF", minHeight: "100vh", color: "black" }}
    >
      <HomepageNav />
      <Container maxWidth="lg" sx={{ p: 5 }}>
        <Box>
          {/* Hero Section */}
          <Box sx={{mt:5}}>
          {blog.photos[0] && (
            <StyledHeroImage
              src={blog.photos[0].url}
              alt={blog.title}
              onClick={() => handleClickOpen(blog.photos[0].url)}
              whileHover={{ scale: 1.05 }}
            />
          )}
          </Box>
          

          {/* Blog Title */}
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              color: "#008000",
              fontWeight: "bold",
              textAlign: "center",
              mt: 5,
            }}
          >
            {blog.title}
          </Typography>

          {/* Blog Content */}
          <StyledContent dangerouslySetInnerHTML={{ __html: blog.content }} />

          {/* Author and Date */}
          <StyledAuthorDate>
            <Typography variant="body2">By Cupstone Travel LTD</Typography>
            <Typography variant="body2">{new Date(blog.createdAt).toLocaleDateString()}</Typography>
          </StyledAuthorDate>
        </Box>
      </Container>
      <Footer />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <img src={selectedPhoto} alt={blog.title} style={{ width: "100%" }} />
        </DialogContent>
      </Dialog>
      <FloatingSocialIcons/>
    </div>
  );
};

export default BlogPage;
