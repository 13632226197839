import React from 'react'
import HomepageNav from 'components/HomepageNav'
import HomepageHero from 'components/HomepageHero'
import HomepageDestination from 'components/HomepageDestination'
import adventure from "../assets/adventure.png"
import flight from "../assets/flight.png"
import carrental from "../assets/carrental.png"
import transfer from "../assets/transfer.png"
import holiday from "../assets/holiday.png"
import villa from "../assets/villa.png"
import visa from "../assets/visa.png"
import globe from "../assets/globe.png"
import hotel from "../assets/hotel.png"
import Footer from 'components/Footer'
import FloatingSocialIcons from 'components/FloatingSocialIcons'
import Testimonials from 'components/Testimonials'
import "../styles/testimonial.css"
import { Box } from '@mui/material'

const HomePage = () => {
  return (
    <div>
      <div>
       <HomepageNav />
      </div>
      <div style={{marginTop:"50px"}}>
        <HomepageHero/>
      </div>
        <section className="p-4" style={{ backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
        <Box m="1.5rem 2.5rem">
          <div className="services-container">
            <div className="row justify-content-center text-center mb-3">
              <div className="col-lg-8 col-xl-7">
                <span className="text-muted">At a glance</span>
                <h1 className="display-7 fw-bold" style={{ color: "#008000" }}>Services We Offer</h1>
                <p className="lead display-10" style={{ color: "black" }}>At Cupstone Travel, we take pride in our commitment to offering the best services.</p>
              </div>
            </div>
            <div className="row mt-5 justify-content-center">
              <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                <div className="mb-3">
                  <img src={globe} alt="financed-client" className="business-image" />
                </div>
                <h4 className="fw-bold" style={{ color: "#008000" }}>Road Transport</h4>
                <p style={{ color: "black" }}>Experience reliable road transport solutions for corporate and staff needs, including customized transportation services.</p>
              </div>

              <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                <div className="mb-3">
                  <img src={adventure} alt="financed-client" className="business-image" />
                </div>
                <h4 className="fw-bold" style={{ color: "#008000" }}>Adventure</h4>
                <p style={{ color: "black" }}>Embark on thrilling adventures tailored to your sense of excitement.</p>
              </div>

              <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                <div className="mb-3">
                  <img src={flight} alt="Credit" className="business-image" />
                </div>
                <h4 className="fw-bold" style={{ color: "#008000" }}>Air Ticketing</h4>
                <p style={{ color: "black" }}>Book your flights quickly and easily with our fast travel options.</p>
              </div>

              <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                <div className="mb-3">
                  <img src={carrental} alt="Instant-Credit" className="business-image" />
                </div>
                <h4 className="fw-bold" style={{ color: "#008000" }}>Rent Car</h4>
                <p style={{ color: "black" }}>Enjoy the freedom of the open road with our convenient car rental services.</p>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="mb-3">
                  <img src={villa} alt="Centers" className="business-image" />
                </div>
                <h4 className="fw-bold" style={{ color: "#008000" }}>Affordable Villas</h4>
                <p style={{ color: "black" }}>Relax in comfort with our selection of budget-friendly villas.</p>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="mb-3">
                  <img src={visa} alt="Centers" className="business-image" />
                </div>
                <h4 className="fw-bold" style={{ color: "#008000" }}>Visa Processing</h4>
                <p style={{ color: "black" }}>Streamline your travel with our efficient visa processing services, making international journeys hassle-free.</p>
              </div>

              <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                <div className="mb-3">
                  <img src={transfer} alt="Credit" className="business-image" />
                </div>
                <h4 className="fw-bold" style={{ color: "#008000" }}>Airport Transfer</h4>
                <p style={{ color: "black" }}>Enjoy seamless airport transfers with our prompt and comfortable transportation services, making your arrival and departure smooth.</p>
              </div>

              <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                <div className="mb-3">
                  <img src={holiday} alt="Credit" className="business-image" />
                </div>
                <h4 className="fw-bold" style={{ color: "#008000" }}>Holiday & Leisure</h4>
                <p style={{ color: "black" }}>Explore our curated holiday and leisure packages, including exciting tour safaris for unforgettable experiences.</p>
              </div>
              
              <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                <div className="mb-3">
                  <img src={hotel} alt="Credit" className="business-image" />
                </div>
                <h4 className="fw-bold" style={{ color: "#008000" }}>Hotel Booking</h4>
                <p style={{ color: "black" }}>Find and book the perfect accommodation with our extensive hotel booking services, tailored to suit your preferences.</p>
              </div>
              
              
            </div>
          </div>
          </Box>
        </section>
    
      <div>
          <HomepageDestination/>
      </div>
      <div>
        <Testimonials/>
      </div>
      <div>
        <Footer/>
      </div>
      <div>
        <FloatingSocialIcons/>
      </div>
     
      
    </div>
  )
}

export default HomePage
