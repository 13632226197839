import React, { useState} from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  Button,
  TextField,
  Card,
  CardContent,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetVillaByIdQuery } from "state/api";
import Footer from "components/Footer";
import HomepageNav from "components/HomepageNav";
import styled from "styled-components";
import { motion } from "framer-motion";
import FloatingSocialIcons from "components/FloatingSocialIcons";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const StyledImage = styled(motion.img)`
  max-width: 100%; // Restrict to container width
  max-height: 200px; // Set a max height
  cursor: pointer;
  border-radius: 8px;
  object-fit: cover; // Ensure the image is fully contained within the set dimensions
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;
const ContactForm = styled.form`
display: flex;
flex-direction: column;
gap: 1rem;
padding: 1rem;
background: #e1ebee;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
width: 70%;

@media (max-width: 768px) {
  width: 90%;
  padding: 0.5rem;
}

@media (max-width: 480px) {
  width: 100%;
  padding: 0.5rem;
}
`;

const VillasDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetVillaByIdQuery(id);
  const [open, setOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const villa = data?.data[0]; // Assuming data.data is an array

  if (!villa) {
    return <div>Villa not found</div>;
  }

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleClickOpen = (photo) => {
    setSelectedPhoto(photo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPhoto(null);
  };

  return (
    <div
      style={{ background: "#E8EDF4FF", minHeight: "100vh", color: "black" }}
    >
      <HomepageNav />
      <Box sx={{ p: 10 }}>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          style={{
            color: "#008000",
            marginBottom: "50px",
            fontWeight: "bolder",
          }}
        >
          {capitalizeWords(villa.title)}
        </Typography>

        <Grid
          container
          spacing={1}
          sx={{ mb: 4, border: 1, borderColor: "white", borderRadius: "8px",justifyContent: 'center' }}
        >
          {villa.photos.map((photo, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index} sx={{ padding: '4px', margin: 0 }}>
              <StyledImage
                src={photo.url}
                alt={villa.title}
                onClick={() => handleClickOpen(photo.url)}
                whileHover={{ scale: 1.05 }}
              />
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h2"
            component="h3"
            gutterBottom
            style={{ color: "#008000", fontWeight: "bold" }}
          >
            Details
          </Typography>
          <Typography
            variant="h2"
            component="h4"
            gutterBottom
            style={{ color: "#008000", marginTop: "50px" }}
          >
            Price Per Night
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    KES
                  </Typography>
                  <Typography variant="h6">
                    {new Intl.NumberFormat().format(villa.price)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Typography
            variant="h2"
            component="h4"
            gutterBottom
            style={{ color: "#008000", marginTop: "50px" }}
          >
            Location
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Street
                  </Typography>
                  <Typography variant="h6">{villa.address.street}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Estate
                  </Typography>
                  <Typography variant="h6">{villa.address.estate}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Country Located
                  </Typography>
                  <Typography variant="h6">{villa.address.country}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    City
                  </Typography>
                  <Typography variant="h6">{villa.address.city}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Typography
            variant="h2"
            component="h4"
            gutterBottom
            style={{ color: "#008000", marginTop: "50px" }}
          >
            Basic Features
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Number of Guests
                  </Typography>
                  <Typography variant="body1">
                    {villa.basicFeatures.guests}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Bedrooms
                  </Typography>
                  <Typography variant="body1">
                    {villa.basicFeatures.bedrooms}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    Beds
                  </Typography>
                  <Typography variant="body1">
                    {villa.basicFeatures.beds}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#008000" }}
                  >
                    BathRooms
                  </Typography>
                  <Typography variant="body1">
                    {villa.basicFeatures.bathrooms}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h2" sx={{ color: "#008000" }}>
            Unique Features:
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: villa.uniqueFeatures }} />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h2" sx={{ color: "#008000" }}>
            Description:
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: villa.description }} />
        </Box>

        <Box
          sx={{
            mb: 4,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
        <ContactForm action="https://formsubmit.co/d64a462948377481836474954a60ffbc" method="POST">
          <Typography
            variant="h2"
            component="h3"
            gutterBottom
            style={{ color: "#008000" }}
          >
            Enquire About This House
          </Typography>
          <TextField
                variant="outlined"
                fullWidth
                required
                name="Subject"
                value={`Enquiring about ${villa.title} in ${villa.address.street}`}
                InputProps={{
                  style: { color: 'black' },
                  readOnly: true,
                }}
              />
          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            required
            name="FullName"
            InputProps={{ style: { color: 'black' } }}
            InputLabelProps={{ style: { color: 'black' } }}
            sx={{ backgroundColor: 'white' }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            required
            name="PhoneNumber"
            InputProps={{ style: { color: 'black' } }}
            InputLabelProps={{ style: { color: 'black' } }}
            sx={{ backgroundColor: 'white' }}
          />
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            required
            name="Email"
            InputProps={{ style: { color: 'black' } }}
            InputLabelProps={{ style: { color: 'black' } }}
            sx={{ backgroundColor: 'white' }}
          />
          <TextField
            label="Number of Days Needed"
            variant="outlined"
            fullWidth
            required
            name="DaysNeeded"
            InputProps={{ style: { color: 'black' } }}
            InputLabelProps={{ style: { color: 'black' } }}
            sx={{ backgroundColor: 'white' }}
          />
          <div style={{ margin: "20px 0" }}>
                <label style={{ color: "black" }}>Start Date: </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM/dd/yyyy"
                  name="startDate"
                  required
                  style={{ backgroundColor: "white", color: "black" }}
                />
              </div>
              <div style={{ margin: "20px 0" }}>
                <label style={{ color: "black" }}>End Date: </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="MM/dd/yyyy"
                  name="endDate"
                  required
                  style={{ backgroundColor: "white", color: "black" }}
                />
              </div>
          <TextField
            label="More Queries"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="Queries"
            InputProps={{ style: { color: 'black' } }}
            InputLabelProps={{ style: { color: 'black' } }}
            sx={{ backgroundColor: 'white' }}
          />
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </ContactForm>
        </Box>
      </Box>
      <Footer />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <img
            src={selectedPhoto}
            alt={villa.title}
            style={{ width: "100%" }}
          />
        </DialogContent>
      </Dialog>
      <FloatingSocialIcons />
    </div>
  );
};

export default VillasDetails;
