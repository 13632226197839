import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import loginImage from '../assets/loginImage.jpg';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginUserMutation } from 'state/api';
import { message } from 'antd';
import { setUser } from 'state/userSlice';
import { CgCloseR } from "react-icons/cg";
import "../styles/signin.css"; // Assuming you have a CSS file for styling

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginUser, { isLoading }] = useLoginUserMutation();

  const handleLogin = async () => {
    try {
      const response = await loginUser({ email, password }).unwrap();

      if (response && response.success) {
        localStorage.setItem('token', response.token);
        // dispatch(setUser({ token: response.token, name: response.user.name, role: response.user.role }));
        dispatch(setUser({token: response.token, ...response.user}));
        message.success("Login Successfully");
        navigate('/dashboard');
      } else {
        message.error("Invalid Email or Password");
        if (response && response.message) {
          message.error(response.message);
        }
      }
    } catch (error) {
      message.error("Something went wrong");
      console.error('Error Logging In:', error.message);
    }
  };

  const [show, setShow] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => setShow(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className="container-fluid sign-in-container">
      <Box>
        <Link to='/' className="close-icon">
          <IconButton color='error' size='large'>
            <CgCloseR style={{ fontSize: '28px' }} />
          </IconButton>
        </Link>
      </Box>

      <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
            <Grid item xs={12} md={6}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  '& > *': {
                    m: 2.5,
                    width: 'calc(100% - 20px)'
                  }
                }}
              >
                <Typography variant="h1" component="h1" color="#84B213FF" gutterBottom align="center">
                  Sign In
                </Typography>

                <TextField
                  label="Email"
                  placeholder="example.email@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: '#84B213FF' }
                  }}
                  InputProps={{
                    style: { borderColor: '#84B213FF', color: '#84B213FF' }
                  }}
                  sx={{
                    marginBottom: '30px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#84B213FF'
                      },
                      '&:hover fieldset': {
                        borderColor: '#84B213FF'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#84B213FF'
                      }
                    }
                  }}
                />

                <TextField
                  label="Password"
                  placeholder="Enter at least 8+ characters"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputLabelProps={{
                    style: { color: '#84B213FF' }
                  }}
                  InputProps={{
                    style: { borderColor: '#84B213FF', color: '#84B213FF' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                          color=""
                          sx={{ color: "#84B213FF" }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    marginBottom: '30px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#84B213FF'
                      },
                      '&:hover fieldset': {
                        borderColor: '#84B213FF'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#84B213FF'
                      }
                    }
                  }}
                />

                <Button
                  variant="contained"
                  onClick={handleLogin}
                  disabled={isLoading}
                  sx={{
                    backgroundColor: '#84B213FF',
                    color: 'white',
                    padding: '6px 12px',
                    '&:hover': {
                      backgroundColor: '#84B213FF'
                    }
                  }}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src={loginImage}
                alt="Scenic"
                style={{ width: '70%', height: '60%', borderRadius: '8px', marginLeft: "10px" }}
              />
            </Grid>
          </Grid>
        </Container>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "red" }}>Administrator Sign In</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ color: "black" }}>Sign in is for Administrators only. If you have queries, please visit the
              <span><Link to="/contact" style={{ color: '#008000', cursor: 'pointer' }}> contact page</Link></span>.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" style={{ backgroundColor: '#84B213FF', color: 'white' }} onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default SignIn;
