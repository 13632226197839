import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JS
import video1 from "../assets/video1.mp4"
import video2 from "../assets/video2.mp4"
import video3 from "../assets/video3.mp4"
import video4 from "../assets/video4.mp4"
import "../styles/home.css"

const HomepageHero = () => {
  return (
    <div id="heroCarousel" className="carousel slide" data-bs-ride="carousel" style={{ marginTop: '75px' }}>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <video className="d-block w-100 video" autoPlay loop muted>
            <source src={video4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="carousel-item">
          <video className="d-block w-100 video" autoPlay loop muted>
            <source src={video2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="carousel-item">
          <video className="d-block w-100 video" autoPlay loop muted>
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="carousel-item">
          <video className="d-block w-100 video" autoPlay loop muted>
            <source src={video3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </a>
      <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </a>
      <div className="carousel-caption d-flex flex-column justify-content-center align-items-center text-center h-100">
        <h1 className="text-white fw-bold">Adventure Awaits</h1>
        <h3 className="text-white">Explore the world with us</h3>
      </div>
    </div>
  )
}

export default HomepageHero
