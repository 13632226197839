import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

export default function ProtectedRoute({ children }) {
    const user = useSelector((state) => state.user.user); // Correctly access user
    const [isAuthLoading, setIsAuthLoading] = useState(true);

    useEffect(() => {
        // Just check if user data exists
        setIsAuthLoading(false);
    }, [user]);

    if (isAuthLoading) {
        return <CircularProgress />;
    }

    if (user) {
        return children;
    } else {
        return <Navigate to="/signin" />;
    }
}
