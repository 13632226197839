// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null, // Initial state for user information
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload; // Set user information
    },
    clearUser: (state) => {
      state.user = null; // Clear user information
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
