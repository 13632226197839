import React from 'react';
import "../styles/home.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Link } from 'react-router-dom';
import logo from '../assets/cupstoneLogo.png';


const kenya = 'Kenya';
const uganda = 'Uganda';
const tanzania = 'Tanzania';
const rwanda = 'Rwanda';
const zambia = 'Zambia';

const beach = 'Beach Vacation';
const bush = 'Bush';
const hike= 'Hiking & Camping';
const flight = 'Flight & Balloon Safari';
const photo = 'Photography Safaris';


const HomepageNav = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top bg-light navbar-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img id="MDB-logo" src={logo} alt="MDB Logo" draggable="false" height="50" />
            Cupstone Travel LTD
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto align-items-center">
              {/* Destinations Dropdown */}
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle mx-2 fw-bold" href="#" id="destinationsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-globe fa-fw pe-2"></i>Destinations
                </a>
                <ul className="dropdown-menu" aria-labelledby="destinationsDropdown">
                  <li><a className="dropdown-item" href={`/destinationList/${kenya}`}>Kenya</a></li>
                  <li><a className="dropdown-item" href={`/destinationList/${rwanda}`}>Rwanda</a></li>
                  <li><a className="dropdown-item" href={`/destinationList/${tanzania}`}>Tanzania</a></li>
                  <li><a className="dropdown-item" href={`/destinationList/${uganda}`}>Uganda</a></li>
                  <li><a className="dropdown-item" href={`/destinationList/${zambia}`}>Zambia | Botswana | Namibia</a></li>
                </ul>
              </li>

              {/* Top Deals Dropdown */}
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle mx-2 fw-bold" href="#" id="topDealsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-percent pe-2"></i>Top Deals
                </a>
                <ul className="dropdown-menu" aria-labelledby="topDealsDropdown">
                  <li><Link className="dropdown-item" to={`/topDealList/${beach}`}>Beach Vacation</Link></li>
                  <li><Link className="dropdown-item" to={`/topDealList/${bush}`}>Bush</Link></li>
                  <li><Link className="dropdown-item" to={`/topDealList/${hike}`}>Hiking and Camping</Link></li>
                  <li><Link className="dropdown-item" to={`/topDealList/${flight}`}>Flight & Balloon Safari</Link></li>
                  <li><Link className="dropdown-item" to={`/topDealList/${photo}`}>Photography Safari</Link></li>
                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link mx-2 fw-bold" to="/villasList"><i className="fa fa-bed pe-2"></i>Villas</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2 fw-bold" to="/fleetList"><i className="fa fa-car pe-2"></i>Car Hire</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2 fw-bold" to="/blogList"><i className="fa fa-book pe-2"></i>Blogs</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2 fw-bold" to="/airtickets"><i className="fa fa-plane pe-2"></i>Air Tickets</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2 fw-bold" to="/contact"><i className="fa fa-phone pe-2"></i>Contact Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2 fw-bold" to="/signin"><i className="fa fa-sign-in pe-2"></i>SignIn</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HomepageNav;
